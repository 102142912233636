import React from "react";
import "./styles.scss";
import { InfoSection, OfferBanner } from "../../components";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTab } from "../../actions/general";
import { setLoading } from "../../actions/general";
import SEO from "../../components/SEO/SEO";

export default function Info() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: "smooth" });
    dispatch(changeTab("info"));
    dispatch(setLoading(true));
  }, [dispatch]);

  return (
    <>
      <SEO title={"Synergon Energy Articles and Useful Information"} name={"Synergon Energy Articles and Useful Information"} description={"Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари..."} type={"website"} />
      <div className="screen-wrapper info">
        <div className="screen-inner-wrapper">
          <InfoSection />
          <OfferBanner />
        </div>
      </div>
    </>
  );
}
