const servers = {
    dev: 'https://synergon-admin-api-dev.herokuapp.com/',
    prod: 'https://synergon-admin-api.herokuapp.com/',
    local: 'http://localhost:5000'
}

export const URL = process.env.API_URL || servers.prod

export const s3Credentials = {
    bucketName: 'dev-fidweb',
    dirName: 'synergon-website',
    region: 'eu-central-1',
    accessKeyId: 'AKIA5L7PZ7W5KYJIFZTM',
    secretAccessKey: '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',
}

