import { createSlice } from '@reduxjs/toolkit';
// import httpClient from '../utilities/httpClient';

let lang = localStorage.getItem("code");

const initialState = {
    offerType: '',
    energyType: 'solar energy',
    offerFillType: 'invoice',
    selectedTab: '',
    language: lang || 'bg',
    modal: false,
    loading: false
}

const setLanguage = (payload) => {
    localStorage.setItem('code', payload)
    return payload
}

export const general = createSlice({
    name: 'general',
    initialState,
    reducers: {
        changeLanguage: (state, action) => ({ ...state, language: setLanguage(action.payload) }),
        setModal: (state, action) => ({ ...state, modal: action.payload }),
        changeTab: (state, action) => ({ ...state, selectedTab: action.payload }),
        setOfferType: (state, action) => ({ ...state, offerType: action.payload }),
        setEnergyType: (state, action) => ({ ...state, energyType: action.payload }),
        setOfferFillType: (state, action) => ({ ...state, offerFillType: action.payload }),
        setLoading: (state, action) => ({ ...state, loading: action.payload })
    }
})

export const {
    changeLanguage,
    setModal,
    changeTab, 
    setOfferType,
    setEnergyType,
    setOfferFillType,
    setLoading
} = general.actions

export default general.reducer;