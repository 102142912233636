import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { getNewsCategories, getNewsList } from "../../../actions/news";
import { useInterval } from '../../../utilities/useInterval'
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const shortenText = (text, limit = 200) => {
  if (text.length <= limit) return text;
  else return text.substring(0, limit) + "...";
};

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    observer.observe(ref.current);
    return () => { observer.disconnect(); };
  }, [ref]);
  return isIntersecting;
}

export default function InfoSection() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const language = useSelector(state => state.general.language)

  const [selectedCategory, setSelectedCategory] = useState('all')
  const [categories, setCategories] = useState([])
  useEffect(() => { dispatch(getNewsCategories({ onSuccess: setCategories })) }, [dispatch])

  const [featured, setFeatured] = useState(null)
  const fetchFeatured = useCallback(() => dispatch(getNewsList({
    payload: { featured: true, pagination: false },
    onSuccess: ({ articles }) => setFeatured(articles)
  })), [dispatch])
  useEffect(() => { fetchFeatured(); }, [fetchFeatured]);

  const [allArticles, setAllArticles] = useState(null)
  const { articles = [], hasNextPage, page } = allArticles ?? {};
  const fetch = useCallback((payload = {}) => dispatch(getNewsList({
    payload: { ...(selectedCategory !== 'all' && { category: selectedCategory }), ...payload, pageSize: 4 },
    onSuccess: (data) => {
      setAllArticles((state) => {
        if (data.page > 1) data.articles = [...state.articles, ...data.articles]
        return data
      });
    }
  })), [dispatch, selectedCategory])

  useEffect(() => { fetch(); }, [fetch]);

  const lastArticleRef = useRef(0);
  const isVisible = useIsVisible(lastArticleRef);
  useEffect(() => { if (isVisible && hasNextPage) { fetch({ pageNumber: page + 1 }); } }, [fetch, hasNextPage, isVisible, page]);

  const [featuredIndex, setFeaturedIndex] = useState(0)
  useInterval(() => { setFeaturedIndex((index) => index === featured.length - 1 ? 0 : index + 1) }, featured === null ? null : 5000)

  return (
    <div className={`info-section-container${featured?.[featuredIndex]?.mainPhoto ? ' featured' : ''}`}>
      {featured !== null && featured[featuredIndex] && <div className="info-section-hero-slider-container">
        {featured[featuredIndex].mainPhoto && <div className="image-wrapper"><img src={featured[featuredIndex].mainPhoto} alt='featured main' onClick={() => navigate(`/article/${featured[featuredIndex]._id}`)} /></div>}
        {featured?.length > 1 && <>
          <img className="icon icon-arrow-left" src={require("../../../assets/icons/arrow-left.svg").default} loading='lazy' alt="arrow button" onClick={(e) => { e.stopPropagation(); setFeaturedIndex((featuredIndex) => featuredIndex === 0 ? featured.length - 1 : featuredIndex - 1) }} />
          <img className="icon icon-arrow-right" src={require("../../../assets/icons/arrow-right.svg").default} loading='lazy' alt="arrow button" onClick={(e) => { e.stopPropagation(); setFeaturedIndex((featuredIndex) => featuredIndex === featured.length - 1 ? 0 : featuredIndex + 1) }} />
        </>}
        <div className="text-wrapper" onClick={() => navigate(`/article/${featured[featuredIndex]._id}`)}>
          <p className="category">{featured[featuredIndex].category ? (language === 'en' ? featured[featuredIndex].category.nameEnglish : featured[featuredIndex].category.name) : `${" "}`}</p>
          <h2>{language === 'en' ? featured[featuredIndex].titleEnglish : featured[featuredIndex].title}</h2>
          <span className="createdAt">{moment(featured[featuredIndex].createdAt).format('DD.MM.YYYY')}</span>
        </div>
        {featured?.length > 1 && <div className="mobile-slice-container">
          {featured?.map(({ _id }, index) => <div key={_id} className={index === featuredIndex ? 'active' : ''} onClick={() => setFeaturedIndex(index)} />)}
        </div>}
      </div>}
      <div className="info-section-categories-container row">
        {[{ _id: 'all', name: 'Всички', nameEnglish: 'All' }, ...categories]?.map(({ _id, name, nameEnglish }) => <div
          key={_id}
          className={selectedCategory === _id ? 'active' : ''}
          onClick={() => setSelectedCategory(_id)}
        >{language === 'en' ? nameEnglish : name}</div>)}
      </div>
      <div className="info-section-content">
        {articles?.map(({ _id, mainPhoto, createdAt, category, title, titleEnglish, body, bodyEnglish }) => <div
          key={_id}
          className="single-article-container"
          onClick={() => navigate(`/article/${_id}`)}
        >
          {mainPhoto && <img src={mainPhoto} alt='article main' loading='lazy'/>}
          <div className="text-wrapper">
            <div className="article-header">
              {category && <p className="category">{language === 'en' ? category.nameEnglish : category.name}</p>}
              <h2>{language === 'en' ? titleEnglish : title}</h2>
              <span className="createdAt">{moment(createdAt).format('DD.MM.YYYY')}</span>
            </div>
            <div className="article-body">
              <p dangerouslySetInnerHTML={{ __html: shortenText(language === 'en' ? bodyEnglish : body, 350) }} />
            </div>
            <div className="article-footer" onClick={() => navigate(`/article/${articles[0]._id}`)} >
              <span>{t('general.learnMore')}</span>
              <img className="icon" src={require("../../../assets/icons/arrow-button-right.svg").default} alt="arrow button" loading='lazy'/>
            </div>
          </div>
        </div>)}
        <div ref={lastArticleRef}></div>
      </div>
    </div>
  );
}
