import React, { useEffect, useState } from "react";
import "./styles.scss";
import { OfferBanner } from "../../components";
import ResourcesSection from "../../components/ResourcesSection/ResourcesSection";
import { useSelector, useDispatch } from "react-redux";
import { getFilesList } from "../../actions/resources";
import { useTranslation } from "react-i18next";
import SEO from "../../components/SEO/SEO";

export default function Resources() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.general.language);
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState("questions");
  const [documents, setDocuments] = useState([]);

  const questionsData = [
    {
      question: "Какво е свободен пазар?",
      text: "Свободният (либерализиран) пазар на електрическа енергия в България е създаден и функционира в съответствие с европейските директиви за либерализиране на енергийните пазари. Той дава възможност на участниците в него да договарят свободно цената и условията на доставка на електрическата енергия. По този начин се създава конкурентна среда, която води до оптимизация на цените и подобряване качеството на услугите.",
    },
    {
      question: "Каква е процедурата по смяна на доставчик?",
      text: "Процедурата по смяна на доставчик се извършва от Вашия търговец на електрическа енергия, на когото сте предоставили нотариално заверено пълномощно. Процедурата стартира при липса на задължения към настоящият Ви доставчик се извършва до 10то число на всеки месец и влиза в сила от 1-во число на следващия месец.",
    },
    {
      question: "Какви са необходимите документи?",
      text: "Необходими документи, за да се стартира процедурата:\n\n1. Сключен договор за покупко-продажба на електрическа енергия с лицензиран доставчик на електрическа енергия и координатор на балансираща група\n\n2. Нотариално заверено пълномощно\n\n3. Липса на просрочени задължения към настоящия доставчик на електрическа енергия.",
    },
    { question: "Мога ли да остана без електрозахранване, ако не избера доставчик?", text: "Не. Ако сте присъединен към мрежата и не сте намерили подходящия за Вас доставчик, ще бъдете снабдяван от досегашния си доставчик, но по завишена цена и служебно определени условия." },
    {
      question: "Какви са предимствата на свободния пазар?",
      text: "Свободният пазар, като всеки друг конкурентен пазар, дава възможност на клиентите сами да изберат доставчик на електрическа енергия. Въвеждането на конкуренция води до реални и по-ниски пазарни цени, подобряване на ефективността и обслужването. Клиентите могат да договорят цената на електрическата енергия и условията на договора с доставчика.",
    },
    { question: "Как се определят цените на електроенергията?", text: "На свободен пазар цените на електрическата енергия се определят на пазарен принцип и се договарят между клиент и търговец. КЕВР регулира само цените на мрежовите услуги, които са едни и същи за обекта на клиента както за свободен, така и за регулиран пазар." },
    { question: "Защо да избера Синергон Енерджи ООД?", text: "Специалистите от нашия екип ще извършат анализ на Вашето потребление и ще изготвят предложение с най-подходящият за Вас продукт. Към предложението ни ще получите и сравнителен анализ на спестяванията, които ще реализирате при подписване на договор с нас." },
    { question: "Колко често мога да сменям доставчика на електроенергия?", text: "Няма нормативни ограничения – всеки клиент сам преценява нуждата си от смяна на доставчика и решава за какъв период от време да сключи договор." },
    {
      question: "Коя част от цената мога да договарям?",
      text: "Единствено цената на активната електрическа енергия подлежи на договаряне (цената за снабдяване). Цената за мрежовите услуги (достъп и пренос през електропреносната и електроразпределителната мрежа) са регулирани от Комисия за енергийно и водно регулиране (КЕВР) и се заплащат от всички клиенти. Цената за „задължения към обществото“ също подлежи на регулиране от КЕВР. За клиентите на регулиран пазар, тази цена е включена в цената на електрическата енергия, а при клиентите на свободен пазар, тя присъства като отделен ред от фактурата.",
    },
    {
      question: "Отделно ли се договаря цената на всяка тарифа?",
      text: "Цената, която клиентите заплащат за дневна и нощна енергия на свободен пазар най-често е единна – т.е. тарифата за 24-часов цикъл е една. За да се достигне до тази цена, внимателно се калкулира профила на потребление и се вземат предвид индивидуалните нужди на всеки клиент – съотношение на дневно и нощно потребление, сезонност и др. При желание на клиента, Синергон Енерджи може да запази разделението на тарифи при условията за доставка на електрическа енергия. За повече информация, моля посетете секция “Продукти и услуги” или се свържете с нас.",
    },
    { question: "Нужно ли е да сменя своя електромер, за да изляза на свободен пазар?", text: "Не, при смяна на доставчик не се сменят средствата за търговски измерване (електромери). Данните, отчетени по тях се предоставят от мрежовия оператор на избрания доставчик за целите на фактурирането." },
    {
      question: "Колко фактури ще получавам?",
      text: "Синергон Енерджи издава една фактура, в края на всеки месец. При сключване на комбиниран договор (задължителен за потребители със стандартизиран товаров профил) потребителят получава единна фактура за енергията и мрежовите услуги. При сключване на договор за доставка на електрическа енергия с потребители с техническа възможност за почасово измерване на консумираната електрическа енергия, Синергон Енерджи фактурира електрическата енергия, акциз и „цена задължения към обществото“, а мрежовите услуги се фактурират от мрежовия оператор.",
    },
    {
      question: "Променя ли се качеството на електрическа енергия на свободен пазар?",
      text: "Не. Независимо кой търговец на енергия изберете, операторът на електроразпределителна мрежа  остава непроменен и той отговаря за качеството на електрическата енергия. Всички въпроси и сигнали относно качеството на снабдяването с електрическа енергия следва да се отправят към съответния оператор на електроразпределителната мрежа, към която са присъединени Вашите обекти.",
    },
    {
      question: "Откъде доставчиците за свободен пазар купуват електроенергия?",
      text: "Търговците могат да купуват електрическа енергия от производители и други търговци на свободния пазар в България, от Българската независима енергийна борса (БНЕБ) и от други държави, при спазване на изискванията на действащото законодателство за вноса на закупените количества на територията на Република България.",
    },
    { question: "По какъв начин мога да платя сметката си?", text: "Клиентите на „Синергон Енерджи“ ООД мога да заплатят дължимите суми за доставка на електроенергия чрез:\n\n○ Директен дебит\n○ Платежно нареждане\n○ Интернет банкиране\n○ На каса на EasyPay\n○ Online на Epay.bg" },
  ];

  const conceptsData = [
    {
      question: "Такса “Задължения към обществото”",
      text: "Въпреки че за този термин няма директно определение, от разпоредбите на Закона за енергетиката (ЗЕ) може да се заключи, че това са задължения на енергийните предприятия, които са им наложени (т.е. имат задължение да ги изпълняват по смисъла на закон или заповед на държавен орган), свързани със сигурността на снабдяването, защитата на околната среда и енергийната ефективност.",
    },
    {
      question: "Балансираща група",
      text: "„Балансираща група“ е съвкупността от търговски участници (потребители, производители, търговци), които участват общо на пазара на балансираща енергия. Когато броят на участниците в балансиращата група е по-голям от един, се създават условия за групов ефект на балансиране – недостигът или излишъкът на електроенергия при отделните участници взаимно се балансират в рамките на групата и това намалява общия измерен небаланс на групата.",
    },
    { question: "Координатор на „балансираща група“", text: "Всяка балансираща група има координатор. Той поема отговорността за балансиране и финансовите разходи по администриране на заявките към Електроенергийния системен оператор." },
    { question: "Небаланс", text: "„Небаланс“ е разликата между количествата потребена/произведена електрическа енергия и количествата по прогнозите (графиците) за потребление/производство" },
    { question: "Балансиране", text: "„Балансиране“ на търговски участник е компенсирането на разликата между количествата потребена/произведена енергия и количествата по прогнозите (графиците) за потребление/производство." },
    {
      question: "Стандартизираните товарови профили (СТП)",
      text: "Стандартизираните товарови профили (СТП) позволяват на клиентите, които нямат електромери за почасово отчитане на консумираната електрическа енергия, да излязат на свободния пазар, който функционира с отчитане на почасовите количества потребена електрическа енергия. Такива потребители са стопански потребители с предоставена мощност под 100 кВт и битовите потребители. Самите профили представляват коефициенти за почасово разпределение на общото количество потребена електрическа енергия в рамките на една календарна година. Профилът може да бъде различен в зависимост от характера на потребление на даден обект и се определя служебно от мрежовия оператор.",
    },
    { question: "Доставчик от последна инстанция (ДПИ)", text: "ДПИ е доставчик, който осигурява снабдяването с електрическа енергия на клиента, в случай че на свободния пазар клиентът остане без търговец или търговецът му не е в състояние да го снабдява." },
    { question: "Достъп", text: "Достъп – правото за използване на преносната мрежа и/или разпределителните мрежи срещу заплащане на регулирана от КЕВР цена и при условия, определени с наредба." },
    { question: "Пренос", text: "Пренос е такса, за пренос на енергията през преносната и/или разпределителните мрежи. Тя се заплаща на база единица потребена енергия." },
    { question: "Какво е ИТН?", text: "ИТН – Измервателна Точка Номер, е уникален 7-цифрен номер, който идентифицира обекта. С него се получава информация или се правят безкасови плащания. Намира се в блока с данните на клиента, до клиентския номер в месечната фактура." },
  ];
  useEffect(() => {
    dispatch(
      getFilesList({
        onSuccess: (data) => {
          data = data.map((item) => {
            const modifiedItem = {
              link: item.url,
              text: item.title,
              textEnglish: item.titleEnglish,
            };

            return modifiedItem;
          });

          setDocuments(data);
        },
      })
    );

    if (language === "en") setSelectedOption("documents");

    window.scrollTo(0, 0, { behavour: "smooth" });
  }, [dispatch, language]);

  return (
    <>
    <SEO title={"Synergon Energy FAQ"} name={"Synergon Energy FAQ"} description={"Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари..."} type={"website"} />
      <div className="screen-wrapper resources">
        <div className="screen-inner-wrapper">
          <ul className="options-wrapper">
            {language === "bg" && (
              <li className={`option ${selectedOption === "questions" ? "active" : ""}`} onClick={() => setSelectedOption("questions")}>
                Често задавани въпроси
              </li>
            )}
            {language === "bg" && (
              <li className={`option ${selectedOption === "concepts" ? "active" : ""}`} onClick={() => setSelectedOption("concepts")}>
                Понятия
              </li>
            )}
            <li className={`option ${selectedOption === "documents" ? "active" : ""}`} onClick={() => setSelectedOption("documents")}>
              {t("general.documents")}
            </li>
          </ul>

          <div className="main-section-wrapper">
            {selectedOption === "questions" && <ResourcesSection data={questionsData} type="questions" />}
            {selectedOption === "concepts" && <ResourcesSection data={conceptsData} type="questions" />}
            {selectedOption === "documents" && <ResourcesSection data={documents} type="pdf" />}
          </div>
        </div>

        <OfferBanner />
      </div>
    </>
  );
}
