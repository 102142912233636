import React from "react";
import "./styles.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changeTab, changeLanguage } from "../../actions/general";
import { useTranslation } from "react-i18next";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const selectedTab = useSelector((state) => state.general.selectedTab);
  const language = useSelector((state) => state.general.language);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const navigateResponsive = (route) => {
    if (route) {
      navigate(route);
      dispatch(changeTab(route.slice(1)));
    }
    setIsNavOpen(false);
  };

  return (
    <div className="header-wrapper">
      <div className="header-inner-wrapper">
        <div className="mobile">
          <div className={`nav ${isNavOpen ? "opened" : ""}`}>
            {!isNavOpen && (
              <div className="top-wrapper">
                <div className="logo-wrapper">
                  <img
                    src={require("../../assets/icons/logo.svg").default}
                    alt="logo"
                    height={70}
                    onClick={() => {
                      dispatch(changeTab("home"));
                      navigate("/");
                    }}
                  />
                </div>

                <div className="mobile-menu-icon" onClick={() => setIsNavOpen(true)} />
              </div>
            )}

            {isNavOpen && (
              <>
                <div className="top-wrapper">
                  <div className="logo-wrapper">
                    <img src={require("../../assets/icons/logo.svg").default} onClick={() => navigateResponsive("/")} alt="logo" />
                  </div>

                  <img src={require("../../assets/icons/close-icon.svg").default} width={25} height={25} style={{marginRight:"15px"}} alt="close" onClick={() => setIsNavOpen(false)} />
                </div>

                <div className="opened-nav">
                  <li className={`option ${selectedTab === "about" ? "active" : ""}`} onClick={() => navigateResponsive("/about")}>
                    {t("general.headerAbout")}
                  </li>
                  <li className={`option ${selectedTab === "products-and-services" ? "active" : ""}`} onClick={() => navigateResponsive("/products-and-services")}>
                    {t("general.headerProductsAndServices")}
                  </li>
                  <li className={`option ${selectedTab === "partners" ? "active" : ""}`} onClick={() => navigateResponsive("/partners")}>
                    {t("general.headerPartners")}
                  </li>
                  {language === "bg" && (
                    <li className={`option ${selectedTab === "info" ? "active" : ""}`} onClick={() => navigateResponsive("/info")}>
                      {t("general.headerUsefulInfo")}
                    </li>
                  )}
                  <li className={`option ${selectedTab === "resources" ? "active" : ""}`} onClick={() => navigateResponsive("/resources")}>
                    {" "}
                    {t("general.headerResources")}
                  </li>
                  <li className={`option ${selectedTab === "contact" ? "active" : ""}`} onClick={() => navigateResponsive("/contact")}>
                    {t("general.headerContact")}
                  </li>

                  <button onClick={() => navigateResponsive("/offers-initial")}>{t("general.getOffer")}</button>
                </div>
              </>
            )}
          </div>

          {isNavOpen && <div className="background-wrapper"></div>}
        </div>

        <div className="logo-wrapper desktop">
          <img
            src={require("../../assets/icons/logo.svg").default}
            alt="logo"
            onClick={() => {
              dispatch(changeTab("home"));
              navigate("/");
            }}
          />
        </div>

        <div className="nav-wrapper border desktop">
          <NavLink
            to="/about"
            className={`nav-option ${selectedTab === "about" ? "active" : ""}`}
            onClick={() => {
              dispatch(changeTab("about"));
              navigate("/about");
              // setDropdown(false)
            }}
          >
            {t("general.headerAbout")}
          </NavLink>

          <NavLink
            to="/products-and-services"
            className={`nav-option ${selectedTab === "products" ? "active" : ""}`}
            onClick={() => {
              dispatch(changeTab("products"));
              navigate("/products-and-services");
              // setDropdown(false)
            }}
          >
            {t("general.headerProductsAndServices")}
          </NavLink>

          <NavLink
            to="/partners"
            className={`nav-option ${selectedTab === "partners" ? "active" : ""}`}
            onClick={() => {
              dispatch(changeTab("partners"));
              navigate("/partners");
              // setDropdown(false)
            }}
          >
            {t("general.headerPartners")}
          </NavLink>

          {language === "bg" && (
            <NavLink
              to="/info"
              className={`nav-option ${selectedTab === "info" ? "active" : ""}`}
              onClick={() => {
                dispatch(changeTab("info"));
                navigate("/info");
                // setDropdown(false)
              }}
            >
              {t("general.headerUsefulInfo")}
            </NavLink>
          )}

          <NavLink
            to="/resources"
            className={`nav-option ${selectedTab === "resources" ? "active" : ""}`}
            onClick={() => {
              dispatch(changeTab("resources"));
              // setDropdown(false)
            }}
          >
            {t("general.headerResources")}
          </NavLink>

          <NavLink
            to="/contact"
            className={`nav-option ${selectedTab === "contact" ? "active" : ""}`}
            onClick={() => {
              dispatch(changeTab("contact"));
              navigate("/contact");
              // setDropdown(false)
            }}
          >
            {t("general.headerContact")}
          </NavLink>
        </div>

        <div className="right-wrapper border desktop">
          <div className="divider" />
          <div className="flex">
            <button className="primary-bttn" onClick={() => navigate("/offers-initial")}>
              {t("general.getOffer")}
            </button>

            <div className="languages-container">
              <div
                className={`lang ${language === "bg" ? "active" : ""}`}
                onClick={() => {
                  dispatch(changeLanguage("bg"));
                  i18n.changeLanguage("bg");
                }}
              >
                БГ
              </div>

              <div className="line">|</div>

              <div
                className={`lang ${language === "en" ? "active" : ""}`}
                onClick={() => {
                  dispatch(changeLanguage("en"));
                  i18n.changeLanguage("en");
                }}
              >
                EN
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
