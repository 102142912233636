import { useRef } from "react";
import { uploadFiles } from "../../utilities";
import { setLoading } from "../../actions/general";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const UploadButton = ({ className = "", inputProps, onChange = () => { } }) => {
    const dispatch = useDispatch()
    const uploadRef = useRef();
    const { t } = useTranslation();

    const handleUpload = async ({ target: { files } }) => {
        dispatch(setLoading(true))
        const uploaded = await uploadFiles(files)
        dispatch(setLoading(false))
        
        if (onChange) {
         onChange(uploaded.map(({ location }, i) => ({ address: encodeURI(location), name: files[i].name })))
        }
    }
    
    return (
    <div
        className={`upload-button-container row ${className}`}
        onClick={() => uploadRef.current.click()}>
        <button className="primary-button">{t('general.upload')}</button>

        <input
            ref={uploadRef}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleUpload}
            {...inputProps}
        />
    </div>)
}

export default UploadButton