import React, { useState } from "react"
import "./styles.scss"
import { useEventListener } from "../../utilities/useEventListener"

const ImageGallery = ({ close, photos, selectedImage: selectedImageProp }) => {

    const [selectedImage, setSelectedImage] = useState(selectedImageProp)

    const increment = ({ key }) => {
        if (key === 'ArrowLeft') setSelectedImage((selectedIndex) => selectedIndex === 0 ? photos.length - 1 : selectedIndex - 1)
        if (key === 'ArrowRight') setSelectedImage((selectedIndex) => selectedIndex === photos.length - 1 ? 0 : selectedIndex + 1)
    }
    useEventListener('keydown', increment)

    const handleClickOverlay = (e) => { if (e.target.nodeName === 'DIV') close() }
    return <div className="image-gallery-container" onClick={handleClickOverlay}>
        <div className="image-main-container">
            <img src={require("../../assets/icons/close.svg").default} className="icon-close" alt="close" onClick={close} />
            <img src={photos[selectedImage]} alt='main' />
            <img src={require("../../assets/icons/arrow-orange.svg").default} className="icon-arrow-left" alt="prev" onClick={() => setSelectedImage((selectedIndex) => selectedIndex === 0 ? photos.length - 1 : selectedIndex - 1)} />
            <img src={require("../../assets/icons/arrow-orange.svg").default} className="icon-arrow-right" alt="next" onClick={() => setSelectedImage((selectedIndex) => selectedIndex === photos.length - 1 ? 0 : selectedIndex + 1)} />

        </div>
        <div className="image-gallery-row">
            {photos?.map((el, i) => <img key={el} src={el} alt={`gallery-${i}`} onClick={() => setSelectedImage(i)} loading='lazy'/>)}
        </div>
    </div>
}

export default ImageGallery