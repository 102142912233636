import React, { Suspense } from "react";
import "./styles.scss";
import RadioButton from "../../RadioButton/RadioButton";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useState } from "react";
import { OfferQueryModal } from "../../Modals";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setModal } from "../../../actions/general";
import { sendInquiry } from "../../../actions/inquiries";
import { useTranslation } from "react-i18next";

const DatePicker = React.lazy(() => import("react-datepicker"));

export default function OfferEnergy({ payload, onChange, type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modal = useSelector((state) => state.general.modal);
  const [focusedElement, setFocusedElement] = useState(false);
  const { t } = useTranslation();

  let locationQuery = "";
  let isDisabled = !(payload?.fullName && payload.email !== "" && /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(payload.email) && payload?.phoneNumber && payload?.firmName && payload?.policyAgreement);
  const locationList = [t("offers.dropdownOptionSouth"), t("offers.dropdownOptionWest"), t("offers.dropdownOptionNorth"), t("offers.dropdownOptionGoldenSands")];

  return (
    <div className="offer-inputs-wrapper manufacturers-energy">
      <div className="section-wrapper">
        <div className="section-title">
          <div className="flex start col">
            <h2>{t("offers.inputTitleContactData")}</h2>
          </div>
        </div>

        <div className="section-content">
          <div className="input-wrapper">
            <p>
              {t("offers.inputTitleFullName")} <span className="orange">*</span>
            </p>
            <input value={payload?.fullName} onChange={(e) => onChange({ fullName: e.target.value })}></input>
          </div>

          <div className="input-wrapper">
            <p>
              {t("offers.inputTitleEmail")} <span className="orange">*</span>
            </p>
            <input value={payload?.email} onChange={(e) => onChange({ email: e.target.value })}></input>
          </div>

          <div className="input-wrapper">
            <p>
              {t("general.phoneNumber")} <span className="orange">*</span>
            </p>
            <input value={payload?.phoneNumber} onChange={(e) => onChange({ phoneNumber: e.target.value })}></input>
          </div>

          <div className="input-wrapper last">
            <p>
              {t("offers.inputTitleFirmName")} <span className="orange">*</span>
            </p>
            <input value={payload?.firmName} onChange={(e) => onChange({ firmName: e.target.value })}></input>
          </div>
        </div>
      </div>

      <div className="section-wrapper">
        <div className="section-title">
          <div className="flex start col">
            <h2>{t("offers.additionalData")}</h2>
          </div>
        </div>

        <div className="section-content">
          {type === "solar energy" && (
            <div className="input-wrapper radio wide">
              <p>{t("offers.inputTitleWorkingHeadquarters")}</p>

              <div className="flex start">
                <RadioButton name="headquarters-yes" id="headquarters-yes" value="Yes" text={t("offers.yes")} onChange={(e) => onChange({ workingHeadquarters: "Yes" })} checked={payload?.workingHeadquarters === "Yes"} />

                <RadioButton name="headquarters-no" id="headquarters-no" value="No" text={t("offers.no")} onChange={(e) => onChange({ workingHeadquarters: "No" })} checked={payload?.workingHeadquarters === "No"} />
              </div>
            </div>
          )}

          {payload?.workingHeadquarters === "Yes" && type !== "solar energy" && (
            <div className="input-wrapper calendar">
              <p>{t("offers.inputTitleWorkingSince")}</p>
              <div className="calendar-wrapper">
                <Suspense fallback={<div>Loading...</div>}>
                  <DatePicker
                    placeholderText={t("offers.inputYearPlaceholder")}
                    showYearDropdown
                    showMonthYearPicker
                    dateFormat="mm/yyyy"
                    //   yearDropdownItemNumber={100}
                    maxDate={moment().toDate()}
                    selected={payload.startDate === "" ? "" : new Date(payload.startDate)}
                    onChange={(e) => onChange({ startDate: moment(e).toDate() })}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                  <img className="calendar-icon" alt="calendar" src={require("../../../assets/icons/calendar.svg").default} />
                </Suspense>
              </div>
            </div>
          )}

          {payload?.workingHeadquarters === "Yes" && type === "solar energy" && (
            <div className="input-wrapper calendar">
              <p>{t("offers.inputTitleWorkingSince")}</p>
              <div className="calendar-wrapper">
                <Suspense fallback={<div>Loading...</div>}>
                  <DatePicker
                    placeholderText={t("offers.inputYearPlaceholder")}
                    showYearDropdown
                    showYearPicker
                    dateFormat="yyyy"
                    maxDetail="decade"
                    // yearDropdownItemNumber={100}
                    maxDate={moment().toDate()}
                    selected={payload.startDate === "" ? "" : new Date(payload.startDate)}
                    onChange={(e) => onChange({ startDate: moment(e).toDate() })}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </Suspense>

                <img className="calendar-icon" alt="calendar" src={require("../../../assets/icons/calendar.svg").default} />
              </div>
            </div>
          )}

          {payload?.workingHeadquarters === "No" && type === "solar energy" && (
            <div className="input-wrapper calendar">
              <p>{t("offers.inputTitleStartPlan")}</p>
              <div className="calendar-wrapper">
                <Suspense>
                  <DatePicker
                    placeholderText={t("offers.inputMonthYearPlaceholder")}
                    showYearDropdown
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    //   yearDropdownItemNumber={100}
                    maxDate={moment().toDate()}
                    selected={payload.plannedStartDate === "" ? "" : new Date(payload.plannedStartDate)}
                    onChange={(e) => onChange({ plannedStartDate: moment(e).toDate() })}
                    onKeyDown={(e) => e.preventDefault()}
                  />
                </Suspense>
                <img className="calendar-icon" alt="calendar" src={require("../../../assets/icons/calendar.svg").default} />
              </div>
            </div>
          )}

          {(payload?.workingHeadquarters || type !== "solar energy") && (
            <div className="input-wrapper text-icon">
              <p>{t("offers.inputTitlePower")}</p>

              <div className="text-input-wrapper">
                <input value={payload?.power} onChange={(e) => onChange({ power: e.target.value })}></input>

                <p className="input-text">kW</p>
              </div>
            </div>
          )}

          {(payload?.workingHeadquarters || type !== "solar energy") && (
            <div className="input-wrapper">
              <p>{t("offers.inputTitleTerritory")}</p>

              <div className="input-wrapper dropdown">
                <input
                  placeholder={t("offers.dropdownPlaceholder")}
                  type="text"
                  value={payload?.location}
                  onClick={() => setFocusedElement(!focusedElement)}
                  onChange={(e) => onChange({ location: e.target.value })}
                  onBlur={() => {
                    setTimeout(() => {
                      if (!locationList.includes(locationQuery)) {
                        locationQuery = "";
                        onChange({ location: "" });
                      }
                      setFocusedElement(false);
                    }, 150);
                  }}
                ></input>
                <img className={`arrow ${focusedElement ? "selected" : ""}`} src={require("../../../assets/icons/arrow-down.svg").default} alt="arrow down" onClick={() => setFocusedElement(!focusedElement)} />

                {focusedElement && (
                  <div className="dropdown-wrapper">
                    {locationList
                      .filter((el) => el.toLowerCase().includes(payload?.location?.toLowerCase()))
                      .map((el, i) => {
                        return (
                          <div
                            key={i + 1031}
                            className="dropdown-element"
                            onClick={() => {
                              onChange({ location: el });
                              locationQuery = el;
                              setFocusedElement(false);
                            }}
                          >
                            {el}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          )}

          {(payload?.workingHeadquarters === "Yes" || type !== "solar energy") && (
            <div className="input-wrapper radio last">
              <p>{t("offers.inputTitleContractPremium")}</p>

              <div className="flex start">
                <RadioButton name="preferential-price-yes" id="preferential-price-yes" value="Yes" text={t("offers.yes")} onChange={(e) => onChange({ preferentialPrice: "Yes" })} checked={payload?.preferentialPrice === "Yes"} />

                <RadioButton name="preferential-price-no" id="preferential-price-no" value="No" text={t("offers.no")} onChange={(e) => onChange({ preferentialPrice: "No" })} checked={payload?.preferentialPrice === "No"} />

                <RadioButton name="preferential-price-unknown" id="preferential-price-unknown" value="unknown" text={t("offers.unknown")} onChange={(e) => onChange({ preferentialPrice: "Unknown" })} checked={payload?.preferentialPrice === "Unknown"} />
              </div>
            </div>
          )}

          {payload?.workingHeadquarters === "No" && type === "solar energy" && (
            <div className="input-wrapper radio last">
              <p>{t("offers.inputTitleContractJoin")}</p>

              <div className="flex start">
                <RadioButton name="accession-agreement-yes" id="accession-agreement-yes" value="Yes" text={t("offers.yes")} onChange={(e) => onChange({ accessionAgreement: "Yes" })} checked={payload?.accessionAgreement === "Yes"} />

                <RadioButton name="accession-agreement-no" id="accession-agreement-no" value="No" text={t("offers.no")} onChange={(e) => onChange({ accessionAgreement: "No" })} checked={payload?.accessionAgreement === "No"} />
              </div>
            </div>
          )}

          {payload?.workingHeadquarters && (
            <div className="input-wrapper wide">
              <p>{t("offers.inputTitleComment")}</p>
              <input value={payload?.comment} onChange={(e) => onChange({ comment: e.target.value })}></input>
            </div>
          )}
        </div>
      </div>

      <div className="section-wrapper">
        <div className="section-content no-margin">
          <div className="flex">
            <div className="input-wrapper radio">
              <RadioButton
                name="policy-agreement"
                id="policy-agreement"
                value="policy-agreement"
                type="checkbox"
                text={t("offers.policyAgreement")}
                onChange={(e) => {
                  onChange({ policyAgreement: e.target.checked });
                }}
                //   onChange={(e) => onChange({ policyAgreement: !payload?.policyAgreement })}
                checked={payload?.policyAgreement}
                shape="square"
              />
            </div>

            <button
              className="primary-button"
              disabled={isDisabled}
              onClick={() =>
                dispatch(
                  sendInquiry({
                    data: { ...payload, emailType: "producer" },
                    onSuccess: () => {
                      dispatch(setModal(true));
                    },
                  })
                )
              }
            >
              {t("general.getOffer")}
            </button>
          </div>
        </div>
      </div>

      {modal && (
        <OfferQueryModal
          onClick={() => {
            dispatch(setModal(false));
            navigate("/");
          }}
        />
      )}
    </div>
  );
}
