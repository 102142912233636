import general from "./general";
import { contact } from "./contact";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
    general,
    contact,
  },
});

export default store;
