import React from "react";
import "./styles.scss";
import { ProductsSection, OfferBanner } from "../../components";
import { useEffect } from "react";
import SEO from "../../components/SEO/SEO";

export default function Products() {
  useEffect(() => {
    window.scrollTo(0, 0, { behavour: "smooth" });
  }, []);

  return (
    <>
    <SEO title={"Synergon Energy Products"} name={"Synergon Energy Products"} description={"Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари..."} type={"website"} />
      <div className="screen-wrapper products">
        <div className="screen-inner-wrapper">
          <ProductsSection />
          <OfferBanner />
        </div>
      </div>
    </>
  );
}
