import React from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeTab } from '../../actions/general';
import { useTranslation } from 'react-i18next';

export default function OffersTopSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const offerType = useSelector(state => state.general.offerType);
  const { t } = useTranslation();

  return (
    <div className='offers-top-section-wrapper'>
      {offerType === 'manufacturers' && 
          <div className='section-wrapper top'>
            <img src={require('../../assets/icons/arrow-orange.svg').default} alt='arrow' onClick={() => navigate(-1)} />
            <div className='flex start col'>
              <h2>{t('offers.offersHeaderManufacturersTitle')}</h2>
              <p>{t('offers.offersInitialTextOne')}<span className='link'
              onClick={() => {
              dispatch(changeTab('contact'))
              navigate('/contact')
            }}>{t('offers.offersInitialTextTwo')}</span></p>
            </div>
          </div>}

      {offerType === 'users' && 
          <div className='section-wrapper top'>
            <img src={require('../../assets/icons/arrow-orange.svg').default} alt='arrow' onClick={() => navigate(-1)} />
            <div className='flex start col'>
              <h2>{t('offers.offersHeaderUsersTitle')}</h2>
              <p>{t('offers.offersInitialTextOne')}<span className='link' 
              onClick={() => {
              dispatch(changeTab('contact'))
              navigate('/contact')
            }}>{t('offers.offersInitialTextTwo')}</span></p>
            </div>
          </div>}
    </div>
  )
}
