import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeTab } from "../../actions/general";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo(0, 0);

    switch(pathname) {
        case '/':
        dispatch(changeTab('home'))
        break
        
        case '/about':
        dispatch(changeTab('about'))
        break

        case '/products-and-services':
        dispatch(changeTab('products-and-services'))
        break

        case '/partners':
        dispatch(changeTab('partners'))
        break

        case '/info':
        dispatch(changeTab('info'))
        break

        case '/resources':
        dispatch(changeTab('resources'))
        break

        case '/contact':
        dispatch(changeTab('contact'))
        break
        
        default:
        dispatch(changeTab('home'))
        break
    }

  }, [dispatch, pathname]);

  return null;
}