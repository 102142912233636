import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeTab } from '../../../actions/general';
import { useTranslation } from 'react-i18next';

export default function ProductsSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  return (
    <div className='screen-section-wrapper products'>
        <div className='row'>
           <div className='section-wrapper text'>
                <div className='title-wrapper'>
                  <h1>{t('general.headerProductsAndServices')}</h1>
                  <div className='flex'>
                    <div className='line' />
                    <h2>{t('home.productsAndServicesSectionSubtitle')}</h2>
                  </div>
                </div>
    
                <div className='text-wrapper'>
                    <p>{t('home.productsAndServicesSectionText')}</p>
                </div>
           </div>

           <div className='section-wrapper card end margin desktop'>
            <img className='icon' fetchpriority="low" src={require('../../../assets/icons/electricity.svg').default} alt='electricity icon' />
            <h2>{t('productsAndServices.users')}</h2>
            <p>{t('productsAndServices.usersSubtitle')}</p>
            <div className='link' onClick={() => { 
              dispatch(changeTab('products'))
              navigate('/users')
              }}>
                <span>{t('productsAndServices.productsSeeAll')}</span>
                <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
            </div>
           </div>

           <div className='section-wrapper margin-top mobile'>
             <img className='img-mobile' src={require('../../../assets/images/product-section-background.webp')} alt='background' loading='lazy'/>
              
             <div className='section-wrapper card'>
                <img className='icon' src={require('../../../assets/icons/electricity.svg').default} alt='electricity icon' />
                <h2>{t('productsAndServices.users')}</h2>
                <p>{t('productsAndServices.usersSubtitle')}</p>
                <div className='link' onClick={() => { 
                  dispatch(changeTab('products'))
                  navigate('/users')
                  }}>
                    <span>{t('general.learnMore')}</span>
                    <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
                </div>
               </div>
           </div>
        </div>

        <div className='row manufacturers'>
            <div className='section-wrapper card margin-top desktop'>
               <img className='icon' src={require('../../../assets/icons/solar-panels.svg').default} alt='solar panels' />
               <h2>{t('productsAndServices.manufacturers')}</h2>
               <p>{t('productsAndServices.manufacturersSubtitle')}</p>
               <div className='link' onClick={() => {
                dispatch(changeTab('products'))
                navigate('/manufacturers')
                }}>
                   <span>{t('productsAndServices.seeAllManufacturers')}</span>
                   <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
               </div>
            </div>

            <div className='section-wrapper margin-top mobile'>
              <img className='img-mobile' src={require('../../../assets/images/product-section-2.webp')} alt='product' loading='lazy'/>

              <div className='section-wrapper card'>
                 <img className='icon' src={require('../../../assets/icons/solar-panels.svg').default} alt='solar panels' />
                 <h2>{t('productsAndServices.manufacturers')}</h2>
                 <p>{t('productsAndServices.manufacturersSubtitle')}</p>
                 <div className='link' onClick={() => {
                  dispatch(changeTab('products'))
                  navigate('/manufacturers')
                  }}>
                     <span>{t('general.learnMore')}</span>
                     <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
                 </div>
              </div>
            </div>

        </div>

        <div className='row services'>
            <div className='section-wrapper card desktop'>
               <img className='icon' src={require('../../../assets/icons/services.svg').default} alt='services icon' />
               <h2>{t('productsAndServices.servicesTitle')}</h2>
               <p>{t('productsAndServices.servicesSubtitle')}</p>
               <div className='link' onClick={() => {
                 dispatch(changeTab('products'))
                 navigate('/services')
                 }}>
                   <span>{t('productsAndServices.seeAllServices')}</span>
                   <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
               </div>
            </div>

            <div className='section-wrapper margin-top mobile'>
              <img className='img-mobile' src={require('../../../assets/images/prouct-section-3.webp')} alt='product' loading='lazy'/>

              <div className='section-wrapper card'>
                 <img className='icon' src={require('../../../assets/icons/services.svg').default} alt='services icon' />
                 <h2>{t('productsAndServices.servicesTitle')}</h2>
                 <p>{t('productsAndServices.servicesSubtitle')}</p>
                 <div className='link' onClick={() => {
                   dispatch(changeTab('products'))
                   navigate('/services')
                   }}>
                     <span>{t('general.learnMore')}</span>
                     <img src={require('../../../assets/icons/arrow-button-right.svg').default} alt='arrow button right' />
                 </div>
              </div>
            </div>
        </div>
    </div>
  )
}
