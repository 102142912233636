import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getArticle } from "../../actions/news";
import moment from "moment";
import { ImageGallery } from "../../components/ImageGallery";
import Popup from "reactjs-popup";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, ViberShareButton, ViberIcon, EmailShareButton, EmailIcon, LinkedinShareButton, LinkedinIcon } from "react-share";
import SEO from "../../components/SEO/SEO";

const Article = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const language = useSelector((state) => state.general.language);

  const [article, setArticle] = useState();

  useEffect(() => {
    if (id && !article) dispatch(getArticle({ id, onSuccess: setArticle }));
  }, [article, dispatch, id]);

  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      <SEO title={article?.title} name={article?.title} image={article?.mainPhoto} description={"Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари."} type={"website"} />
      <div className="screen-article-container">
        <div className="screen-article-back" onClick={() => navigate(-1)}>
          <img src={require("../../assets/icons/arrow-orange.svg").default} className="icon-arrow" alt="back button" />
          <span>{t("general.back")}</span>
        </div>
        <div className="screen-article-header">
          {article?.category && <span className="category">{language === "en" ? article?.category?.nameEnglish : article?.category?.name}</span>}
          <h1>{language === "en" ? article?.titleEnglish : article?.title}</h1>
        </div>
        <div className="screen-article-share">
          <span>{article ? moment(article.createdAt).format("DD.MM.YYYY | HH:mm") : null}</span>
          <div className="icons-container">
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={35} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={35} round={true} />
            </TwitterShareButton>
            <ViberShareButton url={window.location.href}>
              <ViberIcon size={35} round={true} />
            </ViberShareButton>
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={35} round={true} />
            </EmailShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={35} round={true} />
            </LinkedinShareButton>
          </div>
        </div>
        {article?.mainPhoto && (
          <div className="screen-article-main-photo">
            <img src={article?.mainPhoto} alt="article main" onClick={() => setSelectedImage(0)} />
          </div>
        )}
        <div className="screen-article-gallery">
          {article?.photos?.map((el, i) => (
            <img key={el} src={el} alt={`gallery-${i}`} onClick={() => setSelectedImage(i + 1)} />
          ))}
        </div>
        <div className="screen-article-body" dangerouslySetInnerHTML={{ __html: language === "en" ? article?.bodyEnglish : article?.body }} />
        <Popup modal lockScroll overlayStyle={{ zIndex: 100, background: `rgba(0, 0, 0, 0.9)` }} contentStyle={{ width: "auto" }} open={selectedImage !== null} onClose={() => setSelectedImage(null)}>
          <ImageGallery photos={[article?.mainPhoto, ...(article?.photos ?? [])].filter(Boolean)} selectedImage={selectedImage} close={() => setSelectedImage(null)} />
        </Popup>
      </div>
    </>
  );
};

export default Article;
