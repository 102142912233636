import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function OfferBanner() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  return (
    <div className='banner-wrapper'>
        <div className='section left'>
          <h2>{t('general.getIndividualOffer')}</h2>
          <p>{t('general.getOfferText')}</p>
        </div>
        <div className='section right'>
          <button className='primary-button' onClick={() => navigate('/offers-initial')}>{t('general.getOffer')}</button>
        </div>
    </div>
  )
}
