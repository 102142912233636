import React from "react";
import "./styles.scss";
import { HeroSection, AboutSection, OfferBanner, ProductsSection, PartnersSection, InfoSection } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeTab } from "../../actions/general";
import SEO from "../../components/SEO/SEO";

export default function Home() {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.general.language);

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: "smooth" });
    dispatch(changeTab("home"));
  }, [dispatch]);

  return (
    <>
      <SEO title={"Synergon Energy"} name={"Synergon Energy"} description={"Synergon Energy е българският доставчик на ток, който Ви изготвя персонална оферта, грижи се вместо Вас за прехвърлянето и Ви спестява пари."} type={"website"} />
      <div className="screen-wrapper home">
        <div className="screen-inner-wrapper">
          <HeroSection />
          <AboutSection />
          <OfferBanner />
          <ProductsSection />
          <OfferBanner />
          <PartnersSection />
          {language === "bg" && <InfoSection home />}
          <OfferBanner />
        </div>
      </div>
    </>
  );
}
