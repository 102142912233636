import axios from "axios";
// import { toast } from "react-toastify";
// import { stopLoading, store, logout } from "actions";
import { URL } from "../config/settings";

export const httpClient = axios.create({ baseURL: URL });

httpClient.interceptors.request.use(
  async (config) => {
    const tokenStorage = localStorage.getItem("token") ?? "";
    config.headers = { ...(tokenStorage && { Authorization: tokenStorage }) };
    return config;
  },
  (error) => { Promise.reject(error); }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // store.dispatch(stopLoading())
    // if ([401, 403].includes(error?.response?.status)) store.dispatch(logout());
    // else {
    //   const errorMessage = error?.response?.data?.message;
    //   if (errorMessage) toast.error(errorMessage);
    // }
    return Promise.reject(error);
  }
);
