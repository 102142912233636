import React from "react";
import "./styles.scss";
import { OffersTopSection } from "../../../components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEnergyType, setOfferFillType } from "../../../actions/general";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SEO from "../../../components/SEO/SEO";

export default function OffersOptions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const offerType = useSelector((state) => state.general.offerType);

  useEffect(() => {
    window.scrollTo(0, 0, { behavour: "smooth" });
  }, []);

  return (
    <>
      <SEO title={"Synergon Energy Offer-Options"} name={"Synergon Energy Offer-Options"} description={"Вземете оферта - Индивидуална оферта за доставка или изкупуване на електрическа енергия."} type={"website"} />
      <div className="screen-wrapper offers-options">
        <div className="screen-inner-wrapper">
          <OffersTopSection />

          {offerType === "manufacturers" && (
            <div className="section-wrapper bottom">
              <div className="flex start col">
                <h2>{t("offers.productionType")}</h2>
                <p>{t("offers.productionTypeSubtitle")}</p>
              </div>

              <div className="cards-wrapper">
                <div
                  className="card"
                  // onClick={() => navigate('/offer-manufacturer', { state: { offerType: 'manufacturers' }})}
                  onClick={() => {
                    dispatch(setEnergyType("solar energy"));
                    navigate("/offer-manufacturer");
                  }}
                >
                  <img src={require("../../../assets/icons/sun.svg").default} alt="sun" />
                  <h2>{t("offers.solarEnergyType")}</h2>
                </div>

                <div
                  className="card"
                  onClick={() => {
                    dispatch(setEnergyType("hydro energy"));
                    navigate("/offer-manufacturer");
                  }}
                >
                  <img src={require("../../../assets/icons/water-drop.svg").default} alt="water" />
                  <h2>{t("offers.hydroEnergyType")}</h2>
                </div>

                <div
                  className="card"
                  onClick={() => {
                    dispatch(setEnergyType("wind energy"));
                    navigate("/offer-manufacturer");
                  }}
                >
                  <img src={require("../../../assets/icons/wind.svg").default} alt="wind" />
                  <h2>{t("offers.windEnergyType")}</h2>
                </div>

                <div
                  className="card"
                  onClick={() => {
                    dispatch(setEnergyType("other energy"));
                    navigate("/offer-manufacturer");
                  }}
                >
                  <img src={require("../../../assets/icons/menu.svg").default} alt="menu" />
                  <h2>{t("offers.otherEnergyType")}</h2>
                </div>
              </div>
            </div>
          )}

          {offerType === "users" && (
            <div className="section-wrapper bottom">
              <div className="flex start col">
                <h2>{t("offers.getOfferType")}</h2>
                <p>{t("offers.getOfferTypeSubtitle")}</p>
              </div>

              <div className="cards-wrapper start">
                <div
                  className="card"
                  onClick={() => {
                    dispatch(setOfferFillType("invoice"));
                    navigate("/offer-user");
                  }}
                >
                  <img src={require("../../../assets/icons/upload.svg").default} alt="upload" />
                  <h2>{t("offers.uploadInvoice")}</h2>
                </div>

                <div
                  className="card"
                  onClick={() => {
                    dispatch(setOfferFillType("manual"));
                    navigate("/offer-user");
                  }}
                >
                  <img src={require("../../../assets/icons/pen.svg").default} alt="pen" />
                  <h2>{t("offers.insertData")}</h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
