import React, { Suspense } from "react";
import "./styles.scss";
import * as animationData from "../../../assets/animations/loading.json";
const Lottie = React.lazy(() => import("lottie-react"));

export default function LoadingModal() {
  const style = {
    height: 200,
    width: 200,
  };

  return (
    <div className="modal-wrapper loading">
      <div className="content-wrapper">
        <Suspense fallback={<div>Loading animation...</div>}>
          <Lottie animationData={animationData} loop={true} style={style} />
        </Suspense>
      </div>
    </div>
  );
}