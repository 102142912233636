import React from "react";
import './styles.scss';

const RadioButton = ({ name, id, value, onChange, checked, text, shape, type }) => {
  return (
    <label htmlFor={id} className={`radio-label ${shape === 'square' ? 'square' : ''}`}>
      <input
        className="radio-input"
        type={`${type === 'checkbox' ? 'checkbox' : 'radio'}`}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      {text}
    </label>
  )
}

export default RadioButton