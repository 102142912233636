import React, { Fragment, useEffect } from "react";
import "./styles.scss";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function ResourcesSection({ data, type }) {
  const [questionData, setQuestionData] = useState(data[0] || {});
  const language = useSelector(state => state.general.language)

  useEffect(() => {
    if (data && type === "questions") setQuestionData(data[0]);
  }, [data, type]);

  return (
    <div className="resources-section-wrapper">
      {type === "questions" && (
        <div className="content-wrapper">
          <div className="column scroll">
            {data?.map((el, index) => {
              return (
                <Fragment key={`question-${index}`}>
                  <div
                    className={`question-wrapper ${questionData?.question === el?.question ? "selected" : ""}`}
                    onClick={() => setQuestionData({ question: el?.question, text: el?.text })}
                    key={`${el?.question}-${index}`}
                  >
                    <div className="question">{el?.question || ""}</div>
                    {questionData?.question === el?.question ? (
                      <img src={require("../../assets/icons/arrow-orange.svg").default} className="arrow-icon" alt='arrow' />
                    ) : (
                      <img src={require("../../assets/icons/arrow.svg").default} className="arrow-icon" alt='arrow' />
                    )}
                  </div>
                  {window.innerWidth < 1000 && questionData?.question === el?.question && (
                    <div className="column" style={{ margin: "20px 0px" }}>
                      <div className="text" style={{ fontSize: "18px" }}>
                        {questionData?.text}
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>

          {window.innerWidth > 1000 && (
            <div className="column desktop">
              <div className="text" style={{ whiteSpace: 'pre-wrap' }}>{questionData?.text}</div>
            </div>
          )}
        </div>
      )}

      {type === "pdf" && (
        <div className="content-wrapper">
          <div className="column wide">
            {data?.map((el, index) => {
              return (
                <div className="link-wrapper" key={`${el?.text}-${index}`} onClick={() => window.open(el?.link)}>
                  <div className="link">{(language === 'bg' ? el?.text : el?.textEnglish) || ""}</div>
                  <a href={el?.link} target="_blank" rel="noreferrer">
                    <img src={require("../../assets/icons/download.svg").default} className="download-icon" alt="download" />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
