import { httpClient } from '../utilities/httpClient'
export const sendInquiry = ({ data: { emailType, ...data }, onSuccess }) => async (dispatch) => {
    const response = await httpClient.post(`/email/send/${emailType}`, data)
    window.gtag('event', 'page_view', {
        'send_to': 'AW-16691891184',
        'value': 'replace with value',
        'items': [{
            'id': 'replace with value',
            'location_id': 'replace with value',
            'google_business_vertical': 'custom'
        }]
    })
    if (onSuccess) onSuccess(response?.data)
}